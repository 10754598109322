<template>
  <div class="main_box flex">
    <div class="">
      <div
        class="side_bar flexA"
        v-for="(it, idx) in list"
        :key="it.id"
        :class="curIdx == idx ? 'higiLigit' : 'noLight'"
        @click="showCont(it, idx)"
      >
        <img
          src="../assets/Frame01.png"
          alt=""
          class="item_img"
          v-if="curIdx == idx"
        />
        <img src="../assets/Frame02.png" alt="" class="item_img" v-else />
        {{ it.name }}
        <div class="line" v-if="curIdx == idx"></div>
      </div>
    </div>
    <cont-view :ids="id" ref="contview"></cont-view>
  </div>
</template>

<script>
import { getCategory } from "@/api";

import ContView from "../components/ContView.vue";
export default {
  components: { ContView },
  data() {
    return {
      list: [],
      curIdx: 0,
      id: "",
    };
  },
  mounted() {
    this.getCategory();
  },
  methods: {
    showCont(it, idx) {
      console.log(it, idx);
      this.curIdx = idx;
      this.id = it.id;
      this.$refs.contview.id = it.id;
      this.$refs.contview.getInfo();
    },

    async getCategory() {
      try {
        const res = await getCategory(getCategory);
        this.list = res.data[0];
        this.$refs.contview.id = this.list[0].id;
        this.$refs.contview.getInfo();
        console.log("getCategory", res);
      } catch (err) {
        console.log("getCategory", err);
      }
    },
  },
};
</script>

<style>
.line {
  position: absolute;
  top: 26%;
  left: 0;
  width: 2px;
  height: 25px;
  opacity: 1;
  background: rgba(207, 156, 81, 1);
}
.main_box {
  width: 1200px;
  margin: 50px auto 0;
}
.item_img {
  width: 26px;
  height: 26px;
  opacity: 1;
  margin-right: 10px;
}
.side_bar {
  position: relative;
  width: 198px;
  padding: 20px 40px;
  margin-right: 20px;
  box-sizing: border-box;
  opacity: 1;
  font-size: 18px;
  font-weight: 400;
}
.higiLigit {
  background: rgba(255, 251, 242, 1);
  color: rgba(207, 156, 81, 1);
}
.noLight {
  background: rgba(255, 255, 255, 1);
  color: rgba(118, 118, 118, 1);
}
</style>
